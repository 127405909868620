import { richText, stripRichTextWrapperTag } from '@/utils/html';
import { adapter as imageAdapter } from '~/components/cloud-image/utils';

export const highlightV2WrapperTagAdapter = (highlightData) => {
  if (!highlightData) return;

  return {
    content: stripRichTextWrapperTag(highlightData.primary.content),
    icon: highlightData.primary.highlight_icon,
    image:
      highlightData.primary.highlight_image &&
      imageAdapter(
        highlightData.primary.highlight_image,
        highlightData.primary.highlight_image.name
      ),
    title: stripRichTextWrapperTag(highlightData.primary.highlight_title),
    items: (highlightData.items || []).map((item) => ({
      icon: item.item_icon,
      image:
        item.item_image && imageAdapter(item.item_image, item.item_image.name),
      text: stripRichTextWrapperTag(item.item_text),
    })),
  };
};

export default ({ primary, items }) => {
  return {
    isNarrow: primary.is_narrow,
    background: primary.background,
    content: richText(primary.content),
    icon: primary.highlight_icon,
    image:
      primary.highlight_image &&
      imageAdapter(primary.highlight_image, primary.highlight_image.name),
    title: richText(primary.highlight_title),
    items: (items || []).map((item) => ({
      icon: item.item_icon,
      image:
        item.item_image && imageAdapter(item.item_image, item.item_image.name),
      text: richText(item.item_text),
    })),
  };
};
