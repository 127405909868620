import { stripRichTextWrapperTag } from '@/utils/html';
import { highlightV2WrapperTagAdapter } from '~/components/highlight-v2/adapter';

export default async (
  { primary },
  pageData,
  { $prismic, $enhancedLinkSerializer }
) => {
  const moduleId = primary?.edito_image_v2_module?.id;
  if (!moduleId) return null;

  const { data } = await $prismic.api.getByID(moduleId);
  if (!data) return null;

  const highlights =
    data.body.filter(({ slice_type }) => slice_type === 'highlight_v2') || [];

  return {
    uiWrapperWidth: primary.ui_wrapper_width,
    title: stripRichTextWrapperTag(data.title),
    highlights: highlights.map((highlight) =>
      highlightV2WrapperTagAdapter(highlight)
    ),
    image: data.image && {
      alt: data.image.name,
      filename: data.image.cdn_link,
    },
    imageSettings: {
      position: data.image_position,
      borderRadiusOption: data.image_border_radius_option,
    },
    cta: {
      label: data.cta_label,
      variant: data.cta_variant,
      link: $enhancedLinkSerializer(data.cta_link),
    },
  };
};
